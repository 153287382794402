import * as React from "react";
import styled from "styled-components";
import { Header } from "./Header";
import { Text } from "./Text";

const Wrapper = styled.div``;

export const Hero = () => {
  return (
    <Wrapper>
      <div className="inner-wrapper">
        <Header>
          We help machines learn to understand the world the way humans do.
        </Header>
        <Text>
          Our mission is to build human-like general intelligence and make it
          safely accessible in order to foster a more abundant, unconstrained,
          and equitable society.
        </Text>
        <Text>
          We take a first-principles approach, starting from simple
          self-supervised architectures and evolving them to tackle human
          developmental milestones of increasing complexity.
        </Text>
        {/* TODO not sure if this should be here */}
        {/*<Subtext>*/}
        {/*  <sup>1</sup> By "human-like," we mean a system that can solve similar*/}
        {/*  problems to what humans solve when trained on a similar amount and type*/}
        {/*  of data to what humans observe. We set this constraint because—by*/}
        {/*  definition—an intelligent system that requires drastically more or*/}
        {/*  different data would fail to do what humans can do today when there*/}
        {/*  isn't enough of the right data.*/}
        {/*</Subtext>*/}
      </div>
    </Wrapper>
  );
};
