import * as React from "react";
import Layout from "../components/Layout";
import { Col, Row } from "antd";
import { Hero } from "../components/Hero";
import { graphql } from "gatsby";
import CardContainer from "../components/CardContainer";
import styled from "styled-components";
import ListContainer from "../components/ListContainer";
import { WorkWithUs } from "../components/WorkWithUs";
import Card from "../components/Card";
import { LinkWithArrow } from "../components/LinkWithArrow";
import {
  columnProps,
  getPostsFromQuery,
  getReadableDate,
  getRolesFromQuery,
} from "../helpers";
import Link from "gatsby-link";
import {Navigation} from "../components/Navigation";

const Wrapper = styled.div`
  .colored-background {
    background: rgb(245, 245, 245);
  }

  .container:last-child {
    padding-bottom: 5rem;
  }
`;

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.2s;
  &:hover {
    color: hsl(0, 0%, 40%);
  }
  font-weight: 500;
  font-size: 1rem;
  padding: 0.25rem 2rem;
`;

const SeeMoreSection = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 2rem;
`;

const Home = ({ data }) => {
  const posts = getPostsFromQuery(data);
  const roles = getRolesFromQuery(data);
  return (
    <Layout>
      <Wrapper>
        <div className="white-background">
          <Row className="content-row">
            <Col {...columnProps}>
              <Navigation />
              <ListContainer
                className="container"
                header="pinned"
                items={posts.filter((post) => post.group.includes("highlight"))}
              />
              {/*<ListContainer*/}
              {/*  className="container"*/}
              {/*  header="recent posts"*/}
              {/*  items={posts*/}
              {/*    .filter((post) => !post.group.includes("highlight"))*/}
              {/*    }*/}
              {/*/>*/}
              <SeeMoreSection>
                <StyledLink to={"https://generallyintelligent.ai/"}>Want more? Check out the Generally Intelligent blog!</StyledLink>
              </SeeMoreSection>
            </Col>
          </Row>
        </div>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            author
            tags
            group
            layout
          }
          fileAbsolutePath
        }
      }
    }
    allLever {
      edges {
        node {
          id
          createdAt
          text
          hostedUrl
          categories {
            commitment
            location
          }
          descriptionPlain
        }
      }
    }
  }
`;

export default Home;
