import * as React from "react";
import styled from "styled-components";
import { LinkWithArrow } from "./LinkWithArrow";

const Wrapper = styled.div`
  .inner-wrapper {
    padding: 5rem 0;
  }

  .position-link {
    padding-bottom: 2rem;
  }
`;

export const WorkWithUs = () => {
  return (
    <Wrapper>
      <div className="inner-wrapper">
        <h1>Work with us!</h1>
        <p>
          We believe human-like artificial general intelligence can enable a
          society of greater abundance and opportunity — by reducing bottlenecks
          to progress, expanding each person's potential, and giving insight
          into the human mind.{" "}
        </p>
        <p>
          If you're a fearless explorer interested in a principled approach to
          building safe and benevolent artificial general intelligence, come
          work with us!
        </p>
        <div className="position-link">
          <LinkWithArrow to="/#open-positions">
            See open positions
          </LinkWithArrow>
        </div>
        <h2>Our values</h2>
        <h3>Think good</h3>
        <p>
          <strong>Think good</strong> means earnestly — with extraordinary
          curiosity and without ego — trying to understand what is actually
          true. For example: clarifying when a concept feels fuzzy,
          interrogating assumptions, steelmanning counterarguments, and digging
          into inconsistencies.
        </p>
        <h3>Be kind</h3>
        <p>
          <strong>Be kind</strong> means starting from a place of psychological
          safety — having our teammates' best interests at heart while trusting
          that they have ours — and embracing radical candor. Being kind not
          only brings us closer to each other but also embodies our philosophy
          for how AGI can positively impact all of humanity.
        </p>
        <h3>Have fun</h3>
        <p>
          <strong>Having fun</strong> is a critical part of great work; it
          stimulates creativity and resets stress. We devote every Friday
          afternoon to hanging out together in SF, doing everything from playing
          D&D and trying new coffee shops to building funny robots.
        </p>
        <h3>Grow together</h3>
        <p>
          Growth is like compound interest: where you end up depends much more
          on your rate than on your starting point!{" "}
          <strong>Grow together</strong> takes this seriously. We have a weekly
          paper club and we read textbooks as a group. In addition to learning
          together, we each take the initiative to identify areas for personal
          growth and support each other's growth goals.
        </p>
      </div>
    </Wrapper>
  );
};
